/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';
import * as Styled from './contribution.styled';
// import TwoColumnsSection from '~/layouts/twoColumnsSection';
import { getImage as getHomeImage, getOwnMedia } from '~/pages/home/home.utils';
import { useIsSmallScreen } from '~/hooks';
// import './index.css';
import { getLatestLearning, getBanner, getFiles } from '~/pages/home/learning/api';
import * as _ from 'lodash';
import avators from './avators.json'
import Anchor from '~/components/Anchor';
import { times } from 'lodash';

// const getImage = (name) => getHomeImage(`contribution-${name}`);

const Contribution = () => {
  const { t } = useTranslation('page-home');
  const { isSmallScreen } = useIsSmallScreen();

  // const lang = t('contribution', { returnObjects: true });
  // const { guide: guideLang, fork: forkLang } = lang;

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
  const randomTrans = () => ({
    transform: `translate(${getRandomArbitrary(0, 20)}px, ${getRandomArbitrary(-10, 20)}px) scale(${getRandomArbitrary(
      0.5,
      1.2
    )})`,
  });

  const imgsArr = avators.map(it => it.url);
  const contrbutersData = imgsArr.map((img, idx, self) => {
    let size = Math.random() * (25 - -30) + -20 + 160;
    if (img.includes('svg')) {
      size = -30;
    }
    if (idx === Math.floor(self.length / 2)) {
      return (
        <Styled.centerPanel key={idx}>
          <Styled.centerPanelTitle>我们的合作伙伴</Styled.centerPanelTitle>
          <Styled.centerPanelDes>中国Cloud IDE技术社区的建立，离不开这些合作企业和产品的支持！</Styled.centerPanelDes>
        </Styled.centerPanel>
      );
    }
    return (
      <img
        key={`$${idx}_img`}
        style={randomTrans()}
        src={getOwnMedia(img)}
        loading="lazy"
        width={`${size}`}
        alt="Profile Picture"
      />
    );
  });

  const open = (url) => {
    window.open(url)
  }

  return (
    // <Styled.Container id="contribution" isSmallScreen={isSmallScreen}>
    //   <Styled.imagesContainer id="canvas_panel">{contrbutersData}</Styled.imagesContainer>
    // </Styled.Container>
    <div
      style={{
        background: '#fff',
        padding: '4rem 0 6rem',
      }}
    >
      <div
        style={{
          'max-width': 1200,
          'margin-left': 'auto',
          'margin-right': 'auto',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            position: 'relative',
            fontSize: 32,
            color: '#2c2c2c',
          }}
        >
          中国Cloud IDE技术社区的建立，离不开这些合作企业和产品的支持！
        </div>

        <p
          style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontSize: '1.5rem',
          }}
        >
          合作企业
        </p>
        <Row className='mobile-hezuo' wrap={true} gutter={24}>
          <Col span={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 100,
                boxShadow: '0 2px 4px rgba(0,0,0,.1)',
                border: '1px solid #dee2e6',
                cursor: 'pointer'
              }}
              onClick={() => {
                open('https://www.cloudtogo.cn/')
              }}
            >
              <img
                style={{ width: '50%' }}
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/D40A40C9-8458-4b8c-BC5C-DC947A73D7C1.png`}
              ></img>
            </div>
          </Col>
          <Col span={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 100,
                boxShadow: '0 2px 4px rgba(0,0,0,.1)',
                border: '1px solid #dee2e6',
              }}
            >
              <img
                style={{ width: '50%' }}
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/C835CC1C-5736-4832-9CED-9F70A009FBE2.png`}
              ></img>
            </div>
          </Col>
        </Row>

        <p
          style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontSize: '1.5rem',
          }}
        >
          合作产品
        </p>
        <Row className='mobile-hezuo' wrap={true} gutter={24}>
          <Col span={6}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 100,
                boxShadow: '0 2px 4px rgba(0,0,0,.1)',
                border: '1px solid #dee2e6',
                cursor: 'pointer'
              }}
              onClick={() => {
                open('https://www.cloudtogo.cn/titanideinstall')
              }}
            >
              <img
                style={{ width: '50%' }}
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/5B2B7EA6-6E35-443b-B5E9-C2854045633D.png`}
              ></img>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contribution;
