import styled, { css } from 'styled-components';
import { Styled,colors } from '@tidb-community/ui';

const { ModuleTitle, Section } = Styled;

export { ModuleTitle };

export const Container = styled(Section)`
//background: ${colors.M2};

`;

export const Panel = styled.div`
  max-width: 1200px;  
  margin-left: auto;
  margin-right: auto;
`;
export const Module = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ActivitiesBox = styled.div`
  ${(props) =>
    props.isSmallScreen &&
    css`
      margin-bottom: 2rem;
    `}
`;
export const TitleH2 = styled.div`
  font-size: 2rem;
`;

export const DividerS = styled.div`
  width: 3rem;
  height: 10px;
  content: '';
  background: #084779;
  position: relative;
  margin-bottom: 3rem;
`