import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { Row, Col, Divider, Button } from 'antd';
import * as Styled from './activities.styled';
import Activity from './contentPanel';
import * as _ from 'lodash';
import Meetup from './meetup';
import TwoColumnsSection from '~/layouts/twoColumnsSection';
import { Link } from '~/components';
import { PageDataContext } from '~/context';
import { useIsSmallScreen } from '~/hooks';
import { getOwnMedia } from '~/pages/home/home.utils';

// const Meetups = ({ meetups }) => (
//   <>
//     {meetups.map((item, idx) => {
//       const props = {
//         key: idx,
//         ...item,
//       };
//       return <Meetup {...props} />;
//     })}
//   </>
// );

const Activities = () => {
  const { data } = useContext(PageDataContext);
  const { isSmallScreen } = useIsSmallScreen();
  // const { t } = useTranslation('page-home');
  const { others } = data;

  // const lang = t('activities', { returnObjects: true });
  // const viewAllLang = t('common:viewAll');

  // const demoGallery = [
  //   {
  //     link:'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
  //     title: '电商小程序',
  //     coverUrl: getOwnMedia('/uploads/weapp.gif'),
  //     staticCover: getOwnMedia('/uploads/weapppic.png'),
  //     showcode: getOwnMedia('/uploads/weapp_demo.png'),
  //     desc: 'PagePlug 制作的完整电商小程序案例，支持注册、登录、二级商品分类、商品搜索、商品详情、购物车、购买下单、收货地址管理、订单管理等功能。后端接口使用开源后台 litmall 的一个部署实例（实例来自 flutter_mall）'
  //   },
  //   {
  //     link:'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
  //     title: '工程管理系统（PC web应用）',
  //     coverUrl: getOwnMedia('/uploads/menu.gif'),
  //     staticCover: getOwnMedia('/uploads/pcpic.png'),
  //     desc: "PagePlug 制作的一个典型CRUD项目，实现了基本增删改查、后端分页、搜索过滤、弹窗表单、复杂表单等功能"
  //   }
  // ]

  const demoGallery = [
    {
      link: 'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
      title: '电商小程序',
      coverUrl: '/uploads/weapp.gif',
      staticCover: '/uploads/weapppic.png',
      // showcode: '/uploads/weapp_demo.png',
      desc: 'PagePlug 制作的完整电商小程序案例，支持注册、登录、二级商品分类、商品搜索、商品详情、购物车、购买下单、收货地址管理、订单管理等功能。后端接口使用开源后台 litmall 的一个部署实例（实例来自 flutter_mall）',
    },
    {
      link: 'https://lowcode.methodot.com/applications/62a1aae112168d652db26a3b/pages/62a1aae112168d652db26a47',
      title: '工程管理系统（PC web应用）',
      coverUrl: '/uploads/menu.gif',
      staticCover: '/uploads/pcpic.png',
      desc: 'PagePlug 制作的一个典型CRUD项目，实现了基本增删改查、后端分页、搜索过滤、弹窗表单、复杂表单等功能',
    },
  ];

  const demo = {
    demoGallery: _.get(others, 'pageplug.demoGallery', demoGallery),
    preview: _.get(others, 'pageplug.preview', 'https://lowcode.methodot.com/'),
  };

  return (
    <Styled.Container isSmallScreen={isSmallScreen}>
      {/* <Styled.Panel>
        <Styled.TitleH2>在线模板</Styled.TitleH2>
        <Styled.DividerS />

        <Row justify="space-around" wrap={true}>
          <Col span={11}>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <Activity data={demo.demoGallery[0]} isSmallScreen={isSmallScreen} />
            </Styled.ActivitiesBox>
          </Col>
          <Divider type="vertical" />
          <Col span={11}>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <Activity data={demo.demoGallery[1]} isSmallScreen={isSmallScreen} />
            </Styled.ActivitiesBox>
          </Col>
        </Row>
      </Styled.Panel> */}

      <Styled.Panel>
        <Styled.TitleH2>大咖说Cloud IDE</Styled.TitleH2>
        <Styled.DividerS />

        <Row className='mobile-daka' justify="space-around" wrap={true}>
          <Col lg={6} xs={24} className='dataka-col'>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <h3 style={{ marginBottom: 0, color: '#2c2c2c', paddingBottom: '1rem', fontWeight: 'normal' }}>Chris Aniszczyk<br />CNCF/LF CTO</h3>
              <img style={{
                display: 'block',
                marginBottom: '1rem',
                width: 150,
              }} src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/image-20231123114053469.png`} />
              <p>
              Cloud [Native] IDEs Become Normalized. I’m 100% convinced that ephemeral dev workspaces along with the time that developers save in setting up workspaces will propel this technology to an industry norm. 
              </p>
              <p>
              ——《Cloud Native Predictions 2023》
              </p>
            </Styled.ActivitiesBox>
          </Col>
          <Col lg={6} xs={24} className='dataka-col'>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <h3 style={{ marginBottom: 0, color: '#2c2c2c', paddingBottom: '1rem', fontWeight: 'normal' }}>James Governor<br />RedMonk首席分析师和创始人</h3>
              <img style={{
                display: 'block',
                marginBottom: '1rem',
                width: 150,
              }} src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/image-20231123114207054.png`} />
              <p>CDE is about revolutionising how development is done and really taking advantage of cloud services and routing, rather than simply thinking about how to build a browser-based IDE. I bet that around 25% of enterprise software development would be using CDEs within 7 years. Maybe. But If things flip they could flip fast.</p>
              <p>——《The Year of the Cloud Develompent Environment》</p>
            </Styled.ActivitiesBox>
          </Col>
          <Col lg={6} xs={24} className='dataka-col'>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <h3 style={{ marginBottom: 0, color: '#2c2c2c', paddingBottom: '1rem', fontWeight: 'normal' }}>马洪喜<br />行云创新CEO</h3>
              <img style={{
                display: 'block',
                marginBottom: '1rem',
                width: 150,
              }} src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/image-20231128143130432.png`} />
              <p>云原生研发就是企业业务创新的源动力。相较于工业制造领域，软件研发还处于“刀耕火种”的时代，主要体现在研发资产重复、浪费，企业缺乏体系化的研发标准，研发过程无法聚焦业务开发。因此，基于传统模式下的企业创新效率低下，开发工具的变革已迫在眉睫，亟需实现三个“变化”：模块化、标准化和自动化。</p>
              <p>——《2021云栖大会》</p>
            </Styled.ActivitiesBox>
          </Col>
          <Col lg={6} xs={24} className='dataka-col'>
            <Styled.ActivitiesBox isSmallScreen={isSmallScreen}>
              <h3 style={{ marginBottom: 0, color: '#2c2c2c', paddingBottom: '1rem', fontWeight: 'normal' }}>邓冰寒<br />TitanIDE研发负责人</h3>
              <img style={{
                display: 'block',
                marginBottom: '1rem',
                width: 150,
              }} src={`${process.env.NEXT_PUBLIC_CDN_URL}/images/image-20231128143009892.png`} />
              <p>数字化转型是企业发展的必然趋势，数字化研发管理是数字化转型过程中不可缺失的一环。CloudIDE 作为一种基于云计算的集成开发环境，可以帮助企业提高研发效能。</p>
              <p>——《CloudIDE如何提升研发效能》</p>
            </Styled.ActivitiesBox>
          </Col>
        </Row>
      </Styled.Panel>

      {/* <Row justify="center" style={{ marginTop: 20 }}>
        <h1>
          👻进入
          <Button type="link" size="large" href={demo.preview}>
            pageplug 线上体验
          </Button>
          ！导入模板应用JSON，即可开启您的低代码之旅！
        </h1>
      </Row> */}
    </Styled.Container>
  );
};
export default Activities;
