import React from 'react';
import dayjs from 'dayjs';
import { Icon, Avatar } from 'antd'
import * as Styled from './blog.styled';
import Anchor from '~/components/Anchor';
import _ from 'lodash';
// import AsktugIcon from './asktug.svg';

const Blog = ({ type, title, link, author, updatedAt, slug, summary, sourceUrl }) => {
  let linkUrl = type === 'external' ? sourceUrl : `/blog/${slug}`;
  return (
    <Styled.Container>
      <Anchor href={linkUrl}>
        <Styled.TitleRow>
          <h3 title={title}>{title}</h3>
          {/*<AsktugIcon />*/}
        </Styled.TitleRow>
        <Styled.InformationRow>
          {/* <Icon type="user" /> */}
          {/* <Styled.User>
            {author.avatarUrl ? <img alt={author.username} src={author.avatarUrl}/> :
  <Avatar style={{ backgroundColor: '#084779', verticalAlign: 'middle' }} size="small">{(author.username || 'Admin').substring(0,1)}</Avatar>
            }
            &nbsp;&nbsp;
            {author.username}
          </Styled.User> */}
          <span>{dayjs(updatedAt).format('YYYY.MM.DD')}</span>
        </Styled.InformationRow>
      </Anchor>
    </Styled.Container>
  );
};

export default Blog;
