import { api } from '@tidb-community/datasource';
import _ from 'lodash';
import _get from 'lodash/get';

export interface ILatestLearningForHomePage {
  title: string;
  link: string;
  updated_at: Date;
  created_at: Date;
  creator: {
    username: string;
    profile_link: string;
    avatar_url: string;
  };
  categories: api.blog.IMeta[];
}

export async function getLatestLearning(): Promise<any> {
  const result = await api.blog.getLatestLearning();
  return result;
}

export async function getBanner(): Promise<any> {
  const result = await api.blog.getBanner();
  return result;
}

export async function getWelcome(): Promise<any> {
  const result = await api.blog.getWelcome();
  return result;
}
export async function getFiles(): Promise<any> {
  const result = await api.blog.getFiles();
  return result;
}
///https://community.titanide.cn/upload/files?_limit=10&_start=0&_sort=updated_at:DESC
